@use "styles/base";

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: base.$bg-color-page;
}

.tab {
  @extend %card;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tab-buttons {
    display: flex;

    .tab-button {
      flex: 1;
      text-align: center;
      padding: base.$space-sm base.$space-md;
      cursor: pointer;
      background-color: #f4f4f4;

      &.active {
        background-color: #fff;
      }
    }
  }
}

.tab-content {
  display: none;
  padding: base.$space-md;

  &.active {
    display: block;
  }

  label {
    display: block;
    margin: 0.5em 0;

    input[type="email"],
    input[type="password"] {
      display: block;
      margin: base.$space-xs 0;
      width: 100%;
    }
  }

  .err {
    color: base.$color-error;
    font-size: 0.8em;
  }

  .primary-button {
    display: block;
    border: solid 1px transparent;
    outline: 0;
    transition: background-color base.$anim-duration base.$anim-easing;
    padding: base.$space-sm base.$space-md;
    margin: base.$space-sm;
    margin-top: 1em;
    margin-left: auto;

    background-color: base.$color-brand;
    color: base.$btn-primary-text-color;

    &:hover {
      background-color: darken(base.$color-brand, 10%);
    }

    &.disabled,
    &:disabled {
      background-color: base.$bg-color-disabled;
      cursor: default;
    }
  }
}
