@use "styles/base";
@use "styles/common";

.page {
  @extend %page;

  .page-content {
    @extend %page-content;
    padding: base.$space-md;

    table {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: base.$space-sm;
  }

  .action-row {
    display: flex;
    gap: base.$space-sm;
    padding-top: base.$space-md;
  }
}
