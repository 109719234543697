$space-xs: 0.25em;
$space-sm: 0.5em;
$space-md: 1em;
$space-lg: 1.5em;
$space-xl: 2em;
$space-xxl: 2.5em;

$secondary-text-color: #888;
$border-color: #ddd;
$bg-primary: #007bff;
$color-error: #f05458;
$color-danger: $color-error;
$color-danger-hover: darken($color-danger, 10%);
$color-brand: #007cdc;
$color-brand-text: darken($color-brand, 10%);
$color-icon: $color-brand;
$btn-primary-text-color: #fff;
$bg-color-disabled: #888;
$bg-color-page: #f0f2f4;
$tag-bg-color: #f0f2f4;
$table-odd-row-color: lighten($border-color, 8%);
$input-border-color: #bdc7d8;
$input-focus-border-color: #80bdff;
$input-focus-box-shadow-color: rgba(0, 123, 255, 0.25);
$border-radius: 0.2em;
$bg-color-banner: rgba(255, 255, 255, 0.95);
$color-tag-background: #f1f8ff;
$color-tag-count-background: #dddbff;

$z-index-navbar: 1000;

$anim-duration: 0.15s;
$anim-easing: ease-in-out;

%page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

%page-content {
  flex-grow: 1;
  overflow: auto;
}

%card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  padding: $space-md;
}

%navbar {
  z-index: $z-index-navbar;
  padding: $space-sm;
  width: 100%;
  background-color: $bg-color-banner;
  box-shadow: 0 2px 2px $border-color;
}

%button {
  appearance: none;
  background-color: transparent;
  border: solid 1px transparent;
  outline: 0;
  cursor: pointer;
  transition: background-color $anim-duration $anim-easing;
  padding: $space-sm $space-md;

  &.disabled,
  &:disabled {
    pointer-events: none;
    color: inherit;
    opacity: 0.4;
  }
}

%default-button {
  @extend %button;

  border: solid 1px $border-color;
  background-color: #fff;

  &:hover {
    background-color: #ddd;
  }
}

%primary-button {
  @extend %button;

  background-color: $color-brand;
  color: $btn-primary-text-color;

  &:hover {
    background-color: darken($color-brand, 10%);
  }

  &.disabled,
  &:disabled {
    background-color: $bg-color-disabled;
    cursor: default;
  }
}

%icon-only-button {
  @extend %button;

  width: 2.5em;
  height: 2.5em;
  padding: 0;
}

%link {
  color: $color-brand;
  text-decoration: underline;
  cursor: pointer;
}
