@use "styles/base";

.users-page {
  @extend %page;

  .page-content {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    padding: base.$space-md;

    .no-user {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 3em;
      color: base.$secondary-text-color;
      text-align: center;

      svg {
        margin-bottom: base.$space-xs;
        font-size: 2em;
      }
    }
  }
}
