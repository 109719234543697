@use "styles/base";
@use "styles/common";

.list {
  display: flex;
  flex-direction: column;
  padding: base.$space-sm;
}

.buttons {
  display: flex;
  gap: base.$space-md;
}
