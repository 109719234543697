@use "styles/base";

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .primary-link {
    text-decoration: none;
    border: solid 1px transparent;

    transition: background-color base.$anim-duration base.$anim-easing;
    padding: base.$space-sm base.$space-md;
    margin: base.$space-sm;

    background-color: base.$color-brand;
    color: base.$btn-primary-text-color;

    &:hover {
      background-color: darken(base.$color-brand, 10%);
    }
  }
}

.page-content {
  text-align: center;

  h1 {
    margin-bottom: base.$space-md;
  }
}
