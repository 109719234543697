@use "styles/base";

.location-picker {
  display: flex;
  flex-wrap: wrap;
  gap: base.$space-sm;

  .field {
    display: table-row;
    padding: 0;

    .title {
      display: table-cell;
      color: base.$secondary-text-color;
      font-size: 0.8em;
    }
  }

  //   .field + .field {
  //     margin-left: base.$space-sm;
  //   }
}
