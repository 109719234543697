@use "styles/base";

.search-user-page {
  @extend %page;

  .page-content {
    flex-grow: 1;
    overflow: auto;
    display: flex;
  }
}
