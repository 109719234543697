@use "base";

.text-secondary {
  color: base.$secondary-text-color;
}

.light-button {
  @extend %button;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  svg {
    color: base.$color-icon;
  }
}

.primary-button {
  @extend %primary-button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-only-button {
  @extend %icon-only-button;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  svg {
    color: base.$color-icon;
  }
}

.default-button {
  @extend %default-button;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ddd;
  }
}

.radio-label {
  display: inline-block;
  padding: base.$space-xs;
  cursor: pointer;
}

.checkbox-label {
  cursor: pointer;
}
