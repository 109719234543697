@use "styles/base";
@use "styles/common";

.page-footer {
  display: flex;
  align-items: center;
  background-color: base.$bg-color-banner;
  box-shadow: 0 -2px 2px base.$border-color;
  padding: base.$space-sm base.$space-md;

  .stats {
    flex: 1 1 auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: base.$space-md;

    .group {
      display: flex;
      align-items: center;
      gap: base.$space-sm;
    }
  }
}
