@use "styles/base";
@use "styles/common";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: base.$space-sm;
  background-color: base.$bg-color-banner;
  box-shadow: 0 2px 2px base.$border-color;

  .group {
    display: flex;
    align-items: center;
    gap: base.$space-sm;
  }
}
