@use "styles/base";
@use "styles/common";

.add-work-modal {
  textarea {
    width: 100%;
  }

  .image-selector {
    position: relative;
    width: 10em;
    height: 10em;
    border: dashed 1px base.$input-border-color;

    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .cover {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      pointer-events: none;

      &:before {
        content: "点击更改图片";
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: base.$space-sm 0;
        color: #fff;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.7);
        font-size: 0.8em;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}

.field {
  padding: base.$space-sm;

  .title {
    color: base.$secondary-text-color;
    font-size: 0.8em;
  }
}
