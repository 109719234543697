@use "styles/base";

.user-page {
  @extend %page;
}

.page-content {
  @extend %page-content;
  height: 100%;
  padding: base.$space-md;
  position: relative;

  section {
    .section-title {
      font-weight: bold;
      padding: base.$space-xs base.$space-sm;
    }

    .section-body {
      padding: 0 1em;
    }

    .field {
      display: inline-block;
      padding: base.$space-sm;

      .title {
        color: base.$secondary-text-color;
        font-size: 0.8em;
      }

      .no-content {
        color: base.$secondary-text-color;
      }
    }

    .section-content {
      display: block;

      .section-content-item {
        display: block;
      }
    }

    .section-no-content {
      padding: base.$space-sm;
    }
  }

  section + section {
    margin-top: base.$space-md;
  }
}
