@use "styles/base";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Microsoft Yahei",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea,
select {
  padding: base.$space-sm;
  border: 1px solid base.$input-border-color;
  border-radius: base.$border-radius;

  &:focus {
    outline: none;
    border-color: base.$input-focus-border-color;
    box-shadow: 0 0 0 base.$space-xs base.$input-focus-box-shadow-color;
  }
}

textarea {
  width: 45em;
  height: 25em;
}

table {
  border-radius: base.$border-radius;
  border: 1px solid base.$border-color;
  border-collapse: collapse;

  th {
    background-color: base.$border-color;
  }

  th,
  td {
    padding: base.$space-xs base.$space-sm;
  }
}

p + p {
  margin-top: base.$space-md;
  margin-bottom: base.$space-md;
}
