@use "styles/base";
@use "styles/common";

.modal {
  input[type="text"] {
    width: 100%;
  }

  .tag-item {
    display: inline-flex;
    align-items: center;
    font-size: 0.8em;
    border: 0;
    border-radius: base.$space-xs;
    margin: base.$space-xs;
    background-color: base.$color-tag-background;
    overflow: hidden;

    .tag-text {
      padding: base.$space-sm;
    }

    .tag-count {
      border-radius: base.$space-md;
      background-color: base.$color-tag-count-background;
      padding: 0 base.$space-sm;
    }
  }
}

.light-button {
  font-size: 0.7em;
  height: 100%;
  border-left: solid 1px #b4d9ff;
}

.field {
  padding: base.$space-sm;

  .title {
    color: base.$secondary-text-color;
    font-size: 0.8em;
  }
}
