@use "styles/base";

.search-result-panel {
  flex: 1 1 auto;
  height: 100%;

  .message-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    font-size: 3em;
    flex-grow: 1;
    color: base.$secondary-text-color;
    text-align: center;
  }

  .result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
      flex-grow: 1;
      overflow: auto;
    }

    .page-footer {
      width: 100%;
      background-color: base.$bg-color-banner;
      padding: base.$space-sm;
      border-top: solid 1px base.$border-color;
    }
  }
}
