@use "styles/base";
@use "styles/common";

.page {
  @extend %page;
}

.page-content {
  @extend %page-content;
  padding: base.$space-md;

  .help-text {
    color: base.$secondary-text-color;
    font-size: 0.8em;
  }
}

.tag-list {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.tag-item {
  display: inline-flex;
  align-items: center;
  border: 0;
  border-radius: base.$space-xs;
  margin: base.$space-xs;
  background-color: base.$color-tag-background;
  overflow: hidden;
  cursor: pointer;

  .tag-text {
    padding: base.$space-sm;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="text"] {
    width: 30em;
  }

  .field {
    padding: 0;
    margin-bottom: base.$space-md;

    .title {
      color: base.$secondary-text-color;
      font-size: 0.8em;
    }
  }
}
