@use "styles/base";
@use "styles/common";

.job-page {
  @extend %page;

  padding: 0;
  height: 100%;

  .page-content {
    @extend %page-content;
  }

  .top-button-row {
    display: flex;
    gap: base.$space-md;
    padding: base.$space-md;
  }

  .job-body {
    overflow: auto;
    padding: base.$space-md;
  }

  .content-flow {
    display: flex;
  }

  .image {
    width: 28em;
    height: 14em;
  }

  .wechat-share-image {
    width: 25em;
    height: 20em;
  }

  .language {
    display: flex;
    gap: base.$space-sm;
  }

  input[type="file"] {
    display: block;
  }

  .salary-preview {
    color: base.$secondary-text-color;
    line-height: 2.5em;
  }
}

.field {
  padding: base.$space-sm;

  .title {
    color: base.$secondary-text-color;
    font-size: 0.8em;
  }

  .label {
    padding: 0 0.25em;
  }
}

.danger-button {
  @extend %button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: base.$color-danger;

  &:hover {
    background-color: base.$color-danger-hover;
  }
}
