@use "styles/base";

.loading-spinner {
  position: relative;
  width: 1em;
  height: 1em;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    border: 0.05em solid #f4f4f4;
    border-top-color: base.$color-brand;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  text-align: center;
  height: 100%;

  .spinner {
    font-size: 2em;
  }

  .loading-text {
    margin-top: base.$space-lg;
    font-variant: small-caps;
    color: base.$secondary-text-color;
  }
}
