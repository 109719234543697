// @use "sass:color";
@use "styles/base";
@use "styles/common";

.company-detail {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top-button-row {
    display: flex;
    padding: base.$space-md;
    box-shadow: 0 2px 2px base.$border-color;
    gap: base.$space-sm;
  }

  .company-detail-body {
    padding: base.$space-md;
    flex: 1 1 auto;
    overflow: auto;

    .content-flow {
      display: flex;
    }
  }
}

.image-wrapper {
  display: inline-block;
  margin: base.$space-xs base.$space-sm base.$space-xs 0;
  border: dashed 1px base.$border-color;
  padding: base.$space-xs;
  position: relative;

  .float-buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    transition: opacity base.$anim-duration base.$anim-easing;
    padding: base.$space-sm;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:hover {
    .float-buttons {
      opacity: 1;
    }
  }

  .field-image {
    display: block;
    object-fit: cover;
    width: 10em;
    height: 10em;
  }

  .field-text {
    font-size: 0.8em;
    margin-top: base.$space-sm;
  }

  .image-delete-button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(base.$color-danger, 60%);
    //color.fade-out($color: #000000, $amount: 0)

    color: #fff;
    opacity: 0;
    transition: opacity base.$anim-duration base.$anim-easing;
  }

  &:hover {
    .image-delete-button {
      opacity: 1;
    }
  }
}

input[type="file"] {
  display: block;
}

.field {
  padding: base.$space-sm;

  .title {
    color: base.$secondary-text-color;
    font-size: 0.8em;
  }
}

.danger-button {
  @extend %button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: base.$color-danger;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.link-button {
  @extend %button;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: base.$color-brand;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
