@use "styles/base";
@use "styles/common";

.user-row {
  border: solid 1px base.$border-color;
  padding: base.$space-md;

  &.edited {
    box-shadow: inset base.$space-xs 0 0 base.$color-brand;
  }

  .basic-info-row {
    display: flex;
    justify-content: space-between;

    a.basic-info {
      display: flex;
      text-decoration: none;
      color: base.$color-brand-text;

      .name {
        margin-right: base.$space-md;
      }
    }
  }

  .row {
    display: flex;
    gap: base.$space-md;
  }

  .work-exps,
  .education-exps {
    margin-top: base.$space-md;

    .items + .items {
      margin-top: base.$space-xs;
    }
  }

  .items {
    display: flex;
    margin-left: -(base.$space-sm);

    .item {
      padding: 0 (base.$space-sm);
    }

    .item + .item {
      border-left: solid 1px base.$border-color;
    }
  }

  .applied-jobs {
    margin-top: base.$space-md;
    .title {
      font-size: 0.8em;

      .link {
        @extend %link;
      }
    }

    .items {
      margin-top: base.$space-xs;
      margin-bottom: base.$space-xs;
    }
  }
}
