@use "styles/base";
@use "styles/common";

.tags-view {
  width: 100%;
  display: flex;
  margin: base.$space-xs 0;
  flex-wrap: wrap;

  .tag-item {
    display: inline-flex;
    align-items: center;
    font-size: 0.8em;
    border: 0;
    border-radius: base.$space-xs;
    margin: base.$space-xs;
    background-color: base.$color-tag-background;
    overflow: hidden;

    .tag-text {
      padding: base.$space-sm;
    }
  }
}

.tag-editor-wrapper {
  width: 100%;

  .tag-editor {
    width: 100%;
    margin: base.$space-xs 0;
    display: flex;
    flex-wrap: wrap;

    position: relative;
    border: solid 1px base.$border-color;
    border-radius: base.$space-xs;
    cursor: text;

    .tag-item {
      display: inline-flex;
      align-items: center;
      font-size: 0.8em;
      border: 0;
      border-radius: base.$space-xs;
      margin: base.$space-xs;
      background-color: base.$color-tag-background;
      overflow: hidden;

      .tag-text {
        padding: base.$space-sm;
      }
    }

    .tag-input {
      font-size: 0.8em;
      height: 3em;
      border: 0;
      min-width: 10em;
      flex: 1 1 auto;

      &:focus {
        box-shadow: none;
      }
    }

    ul.tag-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 0;
      font-size: 0.8em;
      list-style-type: none;
      background-color: #fff;
      box-shadow: 0 base.$space-xs base.$space-sm rgba(0, 0, 0, 0.2);
      border-radius: 0 0 base.$space-xs base.$space-xs;

      li.tag-option {
        display: flex;
        align-items: center;
        padding: base.$space-sm;

        &:hover {
          background-color: rgba(0, 0, 0, 0.08);
        }

        &.highlight {
          background-color: base.$color-brand;
          color: #fff;
        }
      }
    }

    .light-button {
      font-size: 0.7em;
      height: 100%;
      border-left: solid 1px #b4d9ff;

      svg {
        color: base.$secondary-text-color;
      }
    }
  }
}
