@use "styles/base";

.user-list {
  width: 100%;

  .edit-view {
    width: 100%;
    display: table;
    border-collapse: collapse;

    .user-edit-row {
      display: table-row;

      .check-column,
      .user-column {
        border: solid 1px base.$border-color;
      }

      .check-column {
        display: table-cell;
        padding: base.$space-sm;

        &,
        & > input[type="checkbox"] {
          cursor: pointer;
        }
      }

      .user-column {
        display: table-cell;
        .user-row {
          border: 0;
        }
      }
    }
  }
}
