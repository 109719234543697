@use "styles/base";

.navbar {
  @extend %navbar;
  position: relative;

  .light-button {
    @extend %button;
    display: inline-flex;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    svg {
      color: base.$color-icon;
    }
  }
}
