@use "styles/base";
@use "styles/common";

.search-panel {
  height: 100%;
  display: flex;
  border-right: solid 1px base.$border-color;
  flex: 0 0 auto;

  .main {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .title {
      padding: base.$space-md;
      border-bottom: solid 1px base.$border-color;
    }
  }

  .resize-handle {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.7em;
    color: #fff;
    padding: base.$space-xs;
    background-color: base.$border-color;

    svg {
      width: 0.625em;
    }
  }
}

.main {
  .criteria {
    padding: base.$space-md;
    overflow: auto;

    .field {
      display: table-row;
      padding: 0;

      .title {
        display: table-cell;
        padding: base.$space-sm;
        color: base.$secondary-text-color;
        font-size: 0.8em;
      }

      .field-content {
        padding: base.$space-sm;
      }

      .expected-location-item {
        display: flex;
        align-items: flex-end;
        padding: base.$space-sm;
        gap: base.$space-sm;
      }
    }
  }

  footer {
    background-color: base.$bg-color-banner;
    padding: base.$space-sm;
    border-top: solid 1px base.$border-color;

    .primary-button {
      width: 100%;
    }

    .row {
      display: flex;
      margin-top: base.$space-sm;

      .light-button,
      .button {
        flex: 1 1 auto;
        width: auto;
      }
    }
  }
}
