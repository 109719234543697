@use "styles/base";
@use "styles/common";

.select-tag {
  input[type="search"] {
    margin: base.$space-sm 0;
  }

  .tag-list {
    display: flex;
    flex-direction: column;
    height: 15em;
    padding: base.$space-sm;
    overflow: auto;
    border: solid 1px base.$border-color;
  }
}
