@use "styles/base";

.companies-page {
  height: 100%;
  display: flex;

  .sidebar {
    flex: none;
  }

  .content {
    flex: 1 1 auto;
  }

  .sidebar,
  .content {
    height: 100%;
    overflow: auto;
  }
}

.sidebar {
  width: 10em;
  border-right: 1px solid base.$border-color;
  display: flex;
  flex-direction: column;

  .back-to-home,
  .add-company {
    @extend %button;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: base.$space-sm;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    svg {
      color: base.$color-icon;
    }
  }

  .back-to-home,
  .add-company,
  .company-item {
    padding: base.$space-md;
    width: 100%;
    border: 0;
    border-bottom: 1px solid base.$border-color;
  }

  .company-list {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .company-item {
      &.active {
        box-shadow: inset 0.3em 0 0 base.$color-brand;
      }
    }
  }
}
